import axios from "axios";
import { helper } from "../helper.js";
import Abstract from "./Abstract";
export const base_url = '/admin/cursos';
export const url = process.env.VUE_APP_API_BASE_URL + base_url;
export const abstract = new Abstract(url);
export default class Cursos {


    static get(params) {
        return abstract.get({ ...params });
    }

    static getInscritos(id, params) {
        return axios.get(url + "/inscritos/" + id, { params: { ...params } });
    }

    static download(id) {
        // return axios.get(url +'/'+ id + '/download');

        return axios({
            url:url +'/'+ id + '/download',
            method: "GET",
            responseType: "blob",
          });
    }

    static exportar(id) {
        // return axios.get(url +'/'+ id + '/download');

        return axios({
            url:url +'/'+ id + '/exportar',
            method: "GET",
            responseType: "blob",
          });
    }

    static find(id, params) {
        return abstract.find(id, params);
    }

    static update(model) {
        return axios.post(url + '/' + model.id, helper.getFormData({ ...model, '_method': 'put' }));
    }

    static newModel() {
        return axios.get(url + '/create');
    }

    static create(model) {
        return axios.post(url, helper.getFormData(model));
    }

    static submit(model) {
        return abstract.submit(model, false); // qnd true é passado é para ser convertido em formData
    }

    static delete(id) {
        return abstract.delete(id);
    };


}