import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function get() {
    const options = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    };

    return axios.get(`${url}/admin/me`, options).then(response => {
        return {
            list: response.data,
            meta: response.data
        };
    });
}

function update(profile) {
    // const payload = jsona.serialize({
    //     stuff: profile,
    //     includeNames: []
    // });

    const payload = profile;

    const options = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    };

    return axios
        .patch(`${url}/admin/me`, payload, options)
        .then(response => {
            return jsona.deserialize(response.data);
        });
}

export default {
    get,
    update
};