import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '@/layout/DashboardLayout'
import AuthLayout from '@/layout/AuthLayout'
// Example pages
import UserProfile from "@/views/Examples/UserProfile.vue";
import ListUserPage from "@/views/Examples/UserManagement/ListUserPage.vue";

import ListarUsuarios from "@/views/Usuarios/Listar.vue";
import FormUsuarios from "@/views/Usuarios/Form.vue";

import ListarCursos from "@/views/Cursos/Listar.vue";
import FormCursos from "@/views/Cursos/Form.vue";
import InscritosCursos from "@/views/Cursos/Inscritos.vue";

import ListarCategorias from "@/views/Categorias/Listar.vue";
import FormCategorias from "@/views/Categorias/Form.vue";

import ListarAssociacoes from "@/views/Associacoes/Listar.vue";
import FormAssociacoes from "@/views/Associacoes/Form.vue";

import ListarPedidos from "@/views/Pedidos/Listar.vue";

const PasswordReset = () =>
    import ( /* webpackChunkName: "password" */ "@/views/Password/Reset.vue");
const PasswordEmail = () =>
    import ( /* webpackChunkName: "password" */ "@/views/Password/Email.vue");
Vue.use(Router)

//import middleware
import auth from "@/middleware/auth";
import guest from "@/middleware/guest";

const router = new Router({
    linkExactActiveClass: 'active',
    mode: 'history',
    routes: [{
            path: '/',
            redirect: 'cadastro-congresso',
            component: AuthLayout,
            children: [{
                    path: '/login',
                    name: 'login',
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './views/Login.vue'),
                    meta: { middleware: guest }
                },
                // {
                //     path: '/register',
                //     name: 'register',
                //     component: () =>
                //         import ( /* webpackChunkName: "demo" */ './views/Register.vue'),
                //     meta: { middleware: guest }
                // },
                {
                    path: '/cadastro-congresso',
                    name: 'cadastro-congresso',
                    component: () =>
                        import ('./views/Public/CadastroCongresso.vue'),
                    meta: { middleware: guest }
                },
                {
                    path: '/cadastro-projeto',
                    redirect: '/inscricao-trabalho'
                },
                {
                    path: '/inscricao-trabalho',
                    name: 'inscricao-trabalho',
                    component: () =>
                        import ('./views/Public/CadastroProjeto.vue'),
                    meta: { middleware: guest }
                },
                {
                    path: '/certificado',
                    name: 'certificado',
                    component: () =>
                        import ('./views/Public/Certificado.vue'),
                    meta: { middleware: guest }
                },
                {
                    path: "/password/reset",
                    name: "PasswordReset",
                    component: PasswordReset,
                    meta: { middleware: guest }
                },
                {
                    path: "/password/email",
                    name: "PasswordEmail",
                    component: PasswordEmail,
                    meta: { middleware: guest }
                },
            ]
        },
        {
            path: '/',
            redirect: '/cursos/listar',
        },
        // {
        //     path: '/dashboard',
        //     // redirect: 'cursos/listar',
        //     component: DashboardLayout,
        //     name:DashboardLayout,
        //     children: [{
        //             path: '/dashboard',
        //             name: 'dashboard',
        //             // route level code-splitting
        //             // this generates a separate chunk (about.[hash].js) for this route
        //             // which is lazy-loaded when the route is visited.
        //             component: () =>
        //                 import ( /* webpackChunkName: "demo" */ './views/Dashboard.vue'),
        //             meta: { middleware: auth },
        //         },
        //         {
        //             path: '/icons',
        //             name: 'icons',
        //             component: () =>
        //                 import ( /* webpackChunkName: "demo" */ './views/Icons.vue')
        //         },
        //         {
        //             path: '/profile',
        //             name: 'profile',
        //             component: () =>
        //                 import ( /* webpackChunkName: "demo" */ './views/UserProfile.vue')
        //         },
        //         {
        //             path: '/maps',
        //             name: 'maps',
        //             component: () =>
        //                 import ( /* webpackChunkName: "demo" */ './views/Maps.vue')
        //         },
        //         {
        //             path: '/tables',
        //             name: 'tables',
        //             component: () =>
        //                 import ( /* webpackChunkName: "demo" */ './views/Tables.vue')
        //         }
        //     ]
        // },

        {
            path: "/user",
            component: DashboardLayout,
            name: "user",
            children: [{
                    path: "profile",
                    name: "Perfil de Usuário",
                    components: { default: UserProfile },
                    meta: { middleware: auth }
                },
                {
                    path: "user-management/list-users",
                    name: "List Users",
                    components: { default: ListUserPage },
                    meta: { middleware: auth }
                }
            ]
        },
        {
            path: "/usuarios",
            component: DashboardLayout,
            name: "Usuários",
            children: [{
                    path: "adicionar",
                    name: "Adicionar usuário",
                    components: { default: FormUsuarios },
                    meta: { middleware: auth }
                },
                {
                    path: "editar/:id",
                    name: "Editar usuário",
                    components: { default: FormUsuarios },
                    meta: { middleware: auth }
                },
                {
                    path: "listar",
                    name: "Listar usuários",
                    components: { default: ListarUsuarios },
                    meta: { middleware: auth }
                }
            ]
        },
        {
            path: "/cursos",
            component: DashboardLayout,
            name: "Cursos",
            children: [{
                    path: "adicionar",
                    name: "Adicionar curso",
                    components: { default: FormCursos },
                    meta: { middleware: auth }
                },
                {
                    path: "editar/:id",
                    name: "Editar curso",
                    components: { default: FormCursos },
                    meta: { middleware: auth }
                },
                {
                    path: "listar",
                    name: "Listar curso",
                    components: { default: ListarCursos },
                    meta: { middleware: auth }
                },
                {
                    path: "inscritos/:id",
                    name: "Inscritos curso",
                    components: { default: InscritosCursos },
                    meta: { middleware: auth }
                },
            ]
        },
        {
            path: "/categorias",
            component: DashboardLayout,
            name: "Categorias",
            children: [{
                    path: "adicionar",
                    name: "Adicionar categoria",
                    components: { default: FormCategorias },
                    meta: { middleware: auth }
                },
                {
                    path: "editar/:id",
                    name: "Editar categoria",
                    components: { default: FormCategorias },
                    meta: { middleware: auth }
                },
                {
                    path: "listar",
                    name: "Listar categoria",
                    components: { default: ListarCategorias },
                    meta: { middleware: auth }
                }
            ]
        },
        {
            path: "/associacoes",
            component: DashboardLayout,
            name: "Associacoes",
            children: [{
                    path: "adicionar",
                    name: "Adicionar associação",
                    components: { default: FormAssociacoes },
                    meta: { middleware: auth }
                },
                {
                    path: "editar/:id",
                    name: "Editar associação",
                    components: { default: FormAssociacoes },
                    meta: { middleware: auth }
                },
                {
                    path: "listar",
                    name: "Listar associação",
                    components: { default: ListarAssociacoes },
                    meta: { middleware: auth }
                }
            ]
        },
        {
            path: "/pedidos",
            component: DashboardLayout,
            name: "Pedidos",
            children: [
                /*{
                                    path: "adicionar",
                                    name: "Adicionar categoria",
                                    components: { default: FormCategorias },
                                    meta: { middleware: auth }
                                },*/
                /*{
                    path: "editar/:id",
                    name: "Editar categoria",
                    components: { default: FormCategorias },
                    meta: { middleware: auth }
                },*/
                {
                    path: "listar",
                    name: "Listar Pedidos",
                    components: { default: ListarPedidos },
                    meta: { middleware: auth }
                }
            ]
        },
    ]
});
// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    // If no subsequent Middleware exists,
    // the default `next()` callback is returned.
    if (!subsequentMiddleware)
        return context.next;

    return (...parameters) => {
        // Run the default Vue Router `next()` callback first.
        context.next(...parameters);
        // Then run the subsequent Middleware with a new
        // `nextMiddleware()` callback.
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({...context, next: nextMiddleware });
    };
}

router.beforeEach((to, from, next) => {

    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];
        const context = { from, next, to, router };
        const nextMiddleware = nextFactory(context, middleware, 1);

        return middleware[0]({...context, next: nextMiddleware });
    }

    return next();
});

export default router;