<template>
  <div>
   <base-header class="pb-6 pb-6 pt-5 pt-md-5">
 </base-header>

 <div class="container-fluid mt-5">
  <div class="row">
    <div class="col-xl-12 order-xl-1">
      <card shadow type="secondary">
        <div slot="header" class="bg-white border-0">
          <div class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">Usuário</h3>
            </div>
            <div class="col-4 text-right">
              <!-- <a href="#!" class="btn btn-sm btn-primary">Settings</a> -->
            </div>
          </div>
        </div>
        <template>
          <form @submit.prevent>
            <h6 class="heading-small text-muted mb-4">Informações do usuário</h6>
            
            <div class="row">
              <div class="col-lg-6">
                <base-input
                alternative=""
                required
                label="Nome"
                placeholder="nome do usuário"
                input-classes="form-control-alternative"
                :errors="errors.name"
                v-model="model.name"

                />
              </div>
              <div class="col-lg-6">
                <base-input
                alternative=""
                required
                type="email"
                label="Email"
                placeholder="nome@abflp.com.br"
                input-classes="form-control-alternative"
                :errors="errors.email"
                v-model="model.email"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4">
                <base-input
                alternative=""
                :required="model.id?false:true"
                type="password"
                label="Senha"
                placeholder="********"
                :errors="errors.password"
                input-classes="form-control-alternative"
                v-model="model.password"
                />
              </div>
              <div class="col-lg-4">
                <base-input
                alternative=""
                :required="model.id?false:true"
                type="password"
                label="Confirmação de senha"
                placeholder="********"
                :errors="errors.password_confirmation"
                input-classes="form-control-alternative"
                v-model="model.password_confirmation"
                />
              </div>
              <div class="col-lg-4">
                <base-select
                alternative=""
                label="Função"
                :errors="errors.role_id"
                placeholder="Selecione a função"
                :options="Service.roles()"

                required
                input-classes="form-control-alternative"
                v-model="model.role_id"
                />
              </div>
            </div>

        <!--     <hr class="my-4" />
            
            <h6 class="heading-small text-muted mb-4">
              Informações do operador
            </h6>
            <div class="pl-lg-4">
              <div class="row">
                <div class="col-md-12">
                  <base-input
                  alternative=""
                  label="Address"
                  placeholder="Home Address"
                  input-classes="form-control-alternative"
                  v-model="model.address"
                  />
                </div>
              </div>

            </div> -->

          </form>
        </template>


        <button type="submit" class="btn base-button btn-dark float-left" @click="$router.push('/usuarios/listar')">Ver listagem</button>
        <button type="submit" class="btn base-button btn-primary float-right" @click="submit()" :disabled="loading">Salvar usuário <i class="fa fa-spinner fa-spin" v-if="loading"></i></button>


      </card>
    </div>
  </div>
</div>
</div>
</template>
<script>
  import Service from "@/services/Usuarios";
  export default {
    name: "usuario_form",
    created(){
      if(this.$route.params.id){
        this.model.id =this.$route.params.id;
        this.find();
      }
    }, 
    data() {
      return {
        errors:{},
        loading:false,
        Service,
        model: {
          nome: "",
          email: "",
          password:'',
          password_confirmation:'',
          role_id: null,      
          id:null    
        },
      };
    },
    methods: {
     find(){
      this.loading = true;
      this.errors = {};
      Service.find(this.model.id).then(response => {
        this.model = response.data;
        this.loading = false;        
      }).catch((err) => {
        this.loading = false;
      });
    },
    submit(){
      this.loading = true;
      this.errors = {};
      Service.submit(this.model).then(response => {
        this.model = response.data;
        this.loading = false;
        this.$notify({
          type: "success",
          message: "Usuário salvo com sucesso!",
          horizontalAlign:'center'
        });
        this.model = {
          nome: "",
          email: "",
          password:'',
          password_confirmation:'',
          role_id: null,      
          id:null    
        };
      }).catch((err) => {
        this.loading = false;
        if(err.response && err.response.data && err.response.data.errors){            
          let errors = err.response.data.errors;                      
          this.errors = errors;
          this.$notify({
            type: "danger",
            message: "Por favor verifique o formulário",
            horizontalAlign:'center'
          });
        }
      });;
    },
  }
};
</script>
<style></style>
