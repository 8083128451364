<template>
  <div>
    <base-header class="pb-6 pb-6 pt-5 pt-md-5">
    </base-header>

    <div class="container-fluid mt-5">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Curso</h3>
                </div>
                <div class="col-4 text-right">
                </div>
              </div>
            </div>
            <template>
              <form @submit.prevent>
                <h6 class="heading-small text-muted mb-4">Informações do curso</h6>

                <div class="row">
                  <div class="col-lg-6">
                    <base-radio class="mb-3" v-model="model.ic_congresso" name="true">
                      Este curso é um Congresso
                    </base-radio>
                  </div>
                  <div class="col-lg-6">
                    <base-radio class="mb-3" v-model="model.ic_congresso" name="false">
                      Este curso não é um Congresso
                    </base-radio>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12">
                    <base-input alternative="" required label="Nome" placeholder="nome do curso"
                      input-classes="form-control-alternative" :errors="errors.name" v-model="model.name" />
                  </div>
                </div>
                
                <div class="row">
                  <div class="col-lg-12">
                    <base-input alternative="" label="Descrição" placeholder="descrição do curso"
                      input-classes="form-control-alternative" :errors="errors.description" v-model="model.description" />
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <base-input type="date" alternative="" label="Data Início" placeholder="data início do curso"
                      input-classes="form-control-alternative" :errors="errors.start" v-model="model.start" />
                  </div>
                  <div class="col-lg-6">
                    <base-input type="date" alternative="" label="Data Fim" placeholder="data fim do curso"
                      input-classes="form-control-alternative" :errors="errors.end" v-model="model.end" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <label>Associados</label>
                    <base-checkbox class="mb-3" v-for="associacao in associacoes"
                      v-model="associacoes_checked[associacao.id]">
                      {{ associacao.name }}
                    </base-checkbox>
                  </div>
                </div>
                <div class="pagamentos">
                  <div class="row">
                    <div class="col">
                      <label>Valores por Data, Categoria e Associado</label>
                    </div>
                    <div class="col">
                      <button @click="addPagamento()" class="btn base-button btn-primary float-right">Adicionar
                        Linha</button>
                    </div>
                  </div>
                  <div class="row pagamentos">
                    <div class="col-12">
                      <div v-for="opcao in model.pagamentos">
                        <div class="row">
                          <div class="col-6 col-lg-3">
                            <base-multiselect label="Categorias" required input-classes="form-control-alternative"
                              :data="validItems" v-model="opcao.categorias" @input="getSelecteds($event)"/>

                          </div>
                          <div class="col-6 col-lg-2">
                            <money-input alternative="" label="Valor Normal" placeholder="Valor normal"
                              input-classes="form-control-alternative" v-model="opcao.valor_normal"
                              :options="{ currency: 'BRL' }" required />
                          </div>
                          <div class="col-6 col-lg-2">
                            <money-input alternative="" label="Valor Associado" placeholder="Valor associado"
                              input-classes="form-control-alternative" v-model="opcao.valor_associado"
                              :options="{ currency: 'BRL' }" />
                          </div>
                          <div class="col-6 col-lg-3">
                            <base-input type="date" alternative="" label="Data Limite" placeholder=""
                              input-classes="form-control-alternative" v-model="opcao.data_limite" />
                          </div>
                          <div class="col-6 col-lg-2">
                            <button @click="removePagamento(opcao)"
                              class="btn base-button btn-danger btn-block pagamentos">Remover</button>
                          </div>
                          <hr class="my-3" style="
                                  border: 0;
                                  border-top: 1px solid rgba(0, 0, 0, 0.1);
                                  width: 100%;
                                  overflow: visible;
                                  box-sizing: content-box;
                                  height: 0;
                                " />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </template>
            <button type="submit" class="btn base-button btn-dark float-left" @click="$router.push('/cursos/listar')">Ver
              listagem</button>
            <button type="submit" class="btn base-button btn-primary float-right" @click="submit()" :disabled="loading">Salvar curso <i class="fa fa-spinner fa-spin" v-if="loading"></i></button>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Service from "@/services/Cursos";
import MoneyInput from '../../components/MoneyInput.vue'
export default {
  name: "curso_form",
  components: { MoneyInput },
  created() {
    if (this.$route.params.id) {
      this.model.id = this.$route.params.id;
      this.find();
    } else {
      this.newModel();
    }
  },
  data() {
    return {
      errors: {},
      loading: false,
      Service,
      model: {
        name: "",
        description: "",
        start: "",
        end: "",
        associacoes: [],
        ic_congresso: false,
        id: null,
        pagamentos: [],
      },
      associacoes: [],
      categorias: [],
      associacoes_checked: [],
      alreadySelected: []
    };
  },
  computed: {
    validItems() {
      let aux = [];
      this.categorias.every( item => {
        // if(!this.alreadySelected.includes(item)){
          aux.push(item);
        // }
        return true;
      })
      return aux
    },
  },
  methods: {
    getSelecteds (event) {
      // event.every(item => {
        // this.alreadySelected.push(item);
      // })
      this.alreadySelected = event;
      console.log('input => ', event);
    },
    addPagamento() {
      this.model.pagamentos.push({
        'categorias': [],
        'valor_normal': null,
        'valor_associado': null,
        'data_limite': null
      });
    },
    removePagamento(opcao) {
      const index = this.model.pagamentos.indexOf(opcao);
      if (index > -1) {
        this.model.pagamentos.splice(index, 1);
      }
    },
    setAssociacoes(data) {
      this.associacoes = data;
      data.forEach(associacao => {
        this.associacoes_checked[associacao.id] = this.check(associacao, this.model.associacoes);
      });
    },
    setCategorias(data) {
      this.categorias = data;
    },
    check(item, list) {
      var status = false;
      list.forEach(element => {
        if (element.id === item.id)
          status = true;
      });
      return status;
    },
    newModel() {
      this.loading = true;
      this.errors = {};
      Service.newModel(this.model.id).then(response => {
        this.setAssociacoes(response.data.associacoes)
        this.setCategorias(response.data.categorias)
        this.loading = false;
      }).catch((err) => {
        this.loading = false;
      });
    },
    find() {
      this.loading = true;
      this.errors = {};
      Service.find(this.model.id).then(response => {
        this.model = response.data;
        this.loading = false;
        this.newModel();
      }).catch((err) => {
        this.loading = false;
      });
    },
    submit() {
      this.loading = true;
      this.errors = {};
      var model = this.model;
      model.associacoes_checked = this.associacoes_checked;
      Service.submit(model).then(response => {
        this.model = response.data;
        this.loading = false;
        this.$notify({
          type: "success",
          message: "Curso salvo com sucesso!",
          horizontalAlign: 'center'
        });
        this.model = {
        name: "",
        description: "",
        start: "",
        end: "",
        associacoes: [],
        ic_congresso: false,
        id: null,
        pagamentos: [],
      };
      }).catch((err) => {
        this.loading = false;
        if (err.response && err.response.data && err.response.data.errors) {
          let errors = err.response.data.errors;
          this.errors = errors;
          this.$notify({
            type: "danger",
            message: "Por favor verifique o formulário",
            horizontalAlign: 'center'
          });
        }
      });
    },
  }
};
</script>
<style>
.pagamentos {
  margin-top: 1em;
}
</style>
