<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-6 pt-5 pt-md-5">
    </base-header>
    <div class="container-fluid mt-5">
      <card body-classes="px-0 pb-1" footer-classes="pb-2">
        <div class="card-header border-0">
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0">
                Lista <i class="fa fa-spinner fa-spin" v-if="loading"></i>
              </h3>
            </div>
            <div class="col text-right">
              <base-button
                type="primary"
                icon
                size="sm"
                @click="$router.push('/cursos/adicionar')"
              >
                <i class="fas fa-user-edit mr-2"></i>
                <span class="btn-inner--text">Adicionar Curso</span>
              </base-button>
            </div>
          </div>
        </div>

        <div class="table-responsive" style="overflow: visible">
          <table
            class="table align-items-center table-flush tablesorter"
            tbody-classes="list"
            :data="list"
          >
            <thead class="thead-light">
              <tr>
                <th @click="orderList('name')">
                  Nome
                  <i
                    :class="`fas fa-arrow-${
                      orderBy.ordem == 'desc' ? 'up' : 'down'
                    }`"
                    v-if="orderBy.campo == 'name'"
                  ></i>
                </th>
                <th @click="orderList('ic_congresso')">
                  Tipo
                  <i
                    :class="`fas fa-arrow-${
                      orderBy.ordem == 'desc' ? 'up' : 'down'
                    }`"
                    v-if="orderBy.campo == 'ic_congresso'"
                  ></i>
                </th>
                <th @click="orderList('start')">
                  Data Inicio - Fim
                  <i
                    :class="`fas fa-arrow-${
                      orderBy.ordem == 'desc' ? 'up' : 'down'
                    }`"
                    v-if="orderBy.campo == 'start'"
                  ></i>
                </th>
                <th @click="orderList('created_at')">
                  Criação
                  <i
                    :class="`fas fa-arrow-${
                      orderBy.ordem == 'desc' ? 'up' : 'down'
                    }`"
                    v-if="orderBy.campo == 'created_at'"
                  ></i>
                </th>
                <th></th>
              </tr>
            </thead>

            <tbody class="list">
              <tr v-for="(row, index) in list" :key="list.id">
                <td>
                  <router-link :to="'/cursos/editar/' + row.id">
                    {{ row.name }}
                  </router-link>
                </td>
                <td>
                  {{ row.type }}
                </td>
                <td>
                  {{ row.start | (date - only) }} -
                  {{ row.end | (date - only) }}
                </td>
                <td>
                  {{ row.created_at | date }}
                </td>
                <!--     <td>
            <a style="cursor: pointer" @click="onProFeature" class="mr-2">
              <i class="fas fa-user-edit"></i>
            </a>
            <a style="cursor: pointer" @click="$root.openExcluirModal(
              row,
              'Você gostaria de excluir o usuário '+ row.name +'?',
              Service)">
              <i class="fas fa-trash"></i>
            </a>
          </td> -->
                <td class="text-right">
                  <base-dropdown class="dropdown" position="right">
                    <a
                      slot="title"
                      class="btn btn-sm btn-icon-only text-light"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="fas fa-ellipsis-v"></i>
                    </a>

                    <template>
                      <router-link
                        class="dropdown-item"
                        :to="'/cursos/inscritos/' + row.id"
                        ><i class="fas fa-users"></i> inscritos</router-link
                      >
                      <router-link
                        class="dropdown-item"
                        :to="'/cursos/editar/' + row.id"
                        ><i class="fas fa-user-edit"></i> editar</router-link
                      >
                      <a
                        class="dropdown-item"
                        href="#"
                        @click="
                          $root.openExcluirModal(
                            row,
                            'Você gostaria de excluir o curso/congresso ' +
                              row.name +
                              '?',
                            Service
                          )
                        "
                        ><i class="fas fa-trash" style="min-width: 20px"></i>
                        excluir</a
                      >
                    </template>
                  </base-dropdown>
                </td>
              </tr>
              <tr v-if="!list.length && !loading">
                <td colspan="100" style="text-align: center">
                  Nenhum curso encontrado
                </td>
              </tr>
              <tr v-if="loading">
                <td colspan="100" style="text-align: center">
                  <i
                    class="fa fa-spinner fa-spin"
                    v-if="loading"
                    style="font-size: 20px"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="card-footer d-flex justify-content-end">
          <base-pagination
            :pagination="pagination"
            @change="listar()"
            align="center"
          ></base-pagination>
        </div>
      </card>
    </div>
    <excluir-modal
      :show="$root.modal.excluir.isOpen"
      :mensagem="$root.modal.excluir.mensagem"
      :model="$root.modal.excluir.model"
      :service="$root.modal.excluir.service"
      @close="handleExcluir"
    ></excluir-modal>
  </div>
</template>
<script>
import Service from "@/services/Cursos";
export default {
  data() {
    return {
      Service,
      loading: false,
      pagination: {
        page: 1,
        last_page: 1,
        per_page: 20,
        total: 1,
      },
      orderBy: {
        campo: "id",
        ordem: "asc",
      },
      list: [
        // {
        //   name: "Admin",
        //   email: "admin@jsonapi.com",
        //   created_at: "2020-01-01",
        // },
      ],
    };
  },
  created() {
    this.listar();
  },
  methods: {
    handleExcluir(updated) {
      this.$root.modal.excluir.isOpen = false;
      if (updated) this.listar();
    },
    listar() {
      this.loading = true;
      this.list = [];
      Service.get({
        ...this.pagination,
        search: this.search,
        ...this.orderBy,
      }).then((response) => {
        this.list = response.data.data;
        this.pagination.last_page = response.data.last_page;
        this.pagination.total = response.data.total;
        this.loading = false;
      });
    },
    orderList(field) {
      this.orderBy.ordem =
        this.orderBy.campo == field && this.orderBy.ordem == "asc"
          ? "desc"
          : "asc";
      this.orderBy.campo = field;
      this.listar();
    },
  },
};
</script>
<style></style>
