<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-6 pt-5 pt-md-5">
    </base-header>
    <div class="container-fluid mt-5">
      <card body-classes="px-0 pb-1" footer-classes="pb-2">
        <div class="card-header border-0">
          <div class="row align-items-center">
            <div class="col-12 col-lg-4">
              <h3 class="mb-0">
                Lista <i class="fa fa-spinner fa-spin" v-if="loading"></i>
              </h3>
            </div>
            <div class="col-12 col-lg-8">
              <div class="row">
                <div class="col-6 col-lg-3">
                  <base-select
                    label="Curso"
                    :options="cursos"
                    v-model="params.item_id"
                  />
                </div>
                <div class="col-6 col-lg-3">
                  <base-select
                    label="Status"
                    :options="Service.getStatus()"
                    v-model="params.status"
                  />
                </div>
                <div class="col-6 col-lg-3">
                  <base-input
                    type="date"
                    label="Inicio do periodo"
                    v-model="params.data_inicio"
                  />
                </div>
                <div class="col-6 col-lg-3">
                  <base-input
                    type="date"
                    label="Fim do periodo"
                    v-model="params.data_fim"
                  />
                </div>
                <div class="col-12 d-flex justify-content-between">
                  <div
                    class="bg-primary text-white p-2 px-3 rounded mr-1 mr-lg-0"
                  >
                    Quantidade de pedidos pagos: {{ qt_pagos }}
                  </div>
                  <div>
                    <button class="btn btn-sm btn-primary" @click="exportar">
                      Exportar
                    </button>
                    <button class="btn btn-sm btn-primary" @click="listar">
                      Filtrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="table-responsive">
          <table
            class="table align-items-center table-flush tablesorter"
            tbody-classes=" list"
            :data="list"
          >
            <thead class="thead-light">
              <tr>
                <th @click="orderList('id')">
                  #ID
                  <i
                    :class="`fas fa-arrow-${
                      orderBy.ordem == 'desc' ? 'up' : 'down'
                    }`"
                    v-if="orderBy.campo == 'id'"
                  ></i>
                </th>
                <th @click="orderList('inscritos.name')">
                  Inscrito
                  <i
                    :class="`fas fa-arrow-${
                      orderBy.ordem == 'desc' ? 'up' : 'down'
                    }`"
                    v-if="orderBy.campo == 'inscritos.name'"
                  ></i>
                </th>
                <th @click="orderList('pedido_items.description')">
                  Cursos
                  <i
                    :class="`fas fa-arrow-${
                      orderBy.ordem == 'desc' ? 'up' : 'down'
                    }`"
                    v-if="orderBy.campo == 'pedido_items.description'"
                  ></i>
                </th>
                <th @click="orderList('total')">
                  Valor
                  <i
                    :class="`fas fa-arrow-${
                      orderBy.ordem == 'desc' ? 'up' : 'down'
                    }`"
                    v-if="orderBy.campo == 'total'"
                  ></i>
                </th>
                <th @click="orderList('pagseguro_status')">
                  Status
                  <i
                    :class="`fas fa-arrow-${
                      orderBy.ordem == 'desc' ? 'up' : 'down'
                    }`"
                    v-if="orderBy.campo == 'pagseguro_status'"
                  ></i>
                </th>
                <th @click="orderList('created_at')">
                  Criação
                  <i
                    :class="`fas fa-arrow-${
                      orderBy.ordem == 'desc' ? 'up' : 'down'
                    }`"
                    v-if="orderBy.campo == 'created_at'"
                  ></i>
                </th>
                <th></th>
              </tr>
            </thead>

            <tbody class="list">
              <tr v-for="(row, index) in list" :key="list.id">
                <td>
                  {{ row.id }}
                </td>
                <td>
                  {{ row.inscrito.name }}
                </td>
                <td>{{ listItems(row.items) }}</td>
                <td>
                  {{ row.total | R$ }}
                </td>
                <td>
                  {{ row.status }}
                </td>
                <td>
                  {{ row.created_at | date }}
                </td>
                <!--     <td>
            <a style="cursor: pointer" @click="onProFeature" class="mr-2">
              <i class="fas fa-user-edit"></i>
            </a>
            <a style="cursor: pointer" @click="$root.openExcluirModal(
              row,
              'Você gostaria de excluir o categoria '+ row.name +'?',
              Service)">
              <i class="fas fa-trash"></i>
            </a>
          </td> -->
                <td class="text-right">
                  <base-dropdown class="dropdown" position="right">
                    <a
                      slot="title"
                      class="btn btn-sm btn-icon-only text-light"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="fas fa-ellipsis-v"></i>
                    </a>

                    <template>
                      <a
                        href="#"
                        v-if="
                          row.pagseguro_status == 3 || row.pagseguro_status == 4
                        "
                        class="dropdown-item"
                        @click="setNoPaid(row.id)"
                        ><i class="fas fa-user-edit"></i> Definir como não
                        pago</a
                      >
                      <a
                        href="#"
                        v-else
                        class="dropdown-item"
                        @click="setPaid(row.id)"
                        ><i class="fas fa-user-edit"></i> Definir como pago</a
                      >
                      <a
                        class="dropdown-item"
                        href="#"
                        @click="
                          $root.openExcluirModal(
                            row,
                            'Você gostaria de excluir o pedido ' + row.id + '?',
                            Service
                          )
                        "
                        ><i class="fas fa-trash" style="min-width: 20px"></i>
                        excluir</a
                      >
                    </template>
                  </base-dropdown>
                </td>
              </tr>
              <tr v-if="!list.length && !loading">
                <td colspan="100" style="text-align: center">
                  Nenhum pedido encontrado
                </td>
              </tr>
              <tr v-if="loading">
                <td colspan="100" style="text-align: center">
                  <i
                    class="fa fa-spinner fa-spin"
                    v-if="loading"
                    style="font-size: 20px"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="card-footer d-flex justify-content-end">
          <base-pagination
            :pagination="pagination"
            @change="listar()"
            align="center"
          ></base-pagination>
        </div>
      </card>
    </div>
    <excluir-modal
      :show="$root.modal.excluir.isOpen"
      :mensagem="$root.modal.excluir.mensagem"
      :model="$root.modal.excluir.model"
      :service="$root.modal.excluir.service"
      @close="handleExcluir"
    ></excluir-modal>
  </div>
</template>
<script>
import Service from "@/services/Pedidos";
import CursosService from "@/services/Cursos";
import FileSaver from "file-saver";

export default {
  data() {
    return {
      Service,
      loading: false,
      pagination: {
        page: 1,
        last_page: 1,
        per_page: 20,
        total: 1,
      },
      qt_pagos: null,
      orderBy: {
        campo: "id",
        ordem: "asc",
      },
      cursos: [],
      params: {
        item_id: null,
        status: null,
        data_inicio: null,
        data_fim: null,
      },
      list: [
        // {
        //   name: "Admin",
        //   email: "admin@jsonapi.com",
        //   created_at: "2020-01-01",
        // },
      ],
    };
  },
  created() {
    this.listar();
    this.setCursos();
  },
  methods: {
    setCursos() {
      CursosService.get({}).then(({ data }) => {
        this.cursos = data;
        this.cursos.unshift({ id: null, name: "Todos" });
      });
    },
    setPaid(id) {
      Service.setPaid(id).then(() => this.listar());
    },
    setNoPaid(id) {
      Service.setNoPaid(id).then(() => this.listar());
    },
    listItems(items) {
      return items.map((item) => item.description).join(", ");
    },
    handleExcluir(updated) {
      this.$root.modal.excluir.isOpen = false;
      if (updated) this.listar();
    },
    listar() {
      this.loading = true;
      this.list = [];
      Service.get({
        ...this.pagination,
        search: this.search,
        ...this.params,
        ...this.orderBy,
      }).then(({ data }) => {
        this.list = data[0].data;
        this.pagination.last_page = data[0].last_page;
        this.pagination.total = data[0].total;
        this.qt_pagos = data[1];
        this.loading = false;
      });
    },
    exportar() {
      Service.exportar({
        search: this.search,
        ...this.params,
      }).then((response) => {
        FileSaver.saveAs(response.data, "pedidos");
      });
    },
    orderList(field) {
      this.orderBy.ordem =
        this.orderBy.campo == field && this.orderBy.ordem == "asc"
          ? "desc"
          : "asc";
      this.orderBy.campo = field;
      this.listar();
    },
    onProFeature() {
      this.$notify({
        type: "danger",
        message: "This is a PRO feature.",
      });
    },
  },
};
</script>
<style>
tr th {
  cursor: pointer;
}
</style>
