import { isEmpty, isEqual, omit, pick } from "lodash";

export default {
    data() {
        return {
            isLoading: false,
            apiValidationErrors: {}
        };
    },
    mounted() {
    },
    methods: {
        /* extract API server validation errors and assigns them to local mixin data */
        setApiValidation(serverErrors, refs = null) {
            this.apiValidationErrors = serverErrors.reduce(
                (accumulator, errorObject) => {
                    if (typeof errorObject.source === "undefined") return false;

                    const errorFieldName = errorObject.fieldName;
                    const errorDetail = errorObject.message;

                    return {

                        [errorFieldName]: errorDetail
                    };
                }, {}
            );
        },

        resetApiValidation() {
            this.apiValidationErrors = {};
        }
    }
};