import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store";
import './registerServiceWorker'
import ArgonDashboard from './plugins/argon-dashboard'
import axios from "axios";
import VueAxios from "vue-axios";
import IsDemo from "./plugins/isDemo"
import moment from "moment"
import VueMask from 'v-mask';
import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3'

const recaptcha = async() => {
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()
    const token = await executeRecaptcha('login')
    return token;
};

Vue.use(VueReCaptcha, { siteKey: '6LfHUV0jAAAAAMvSGqbF7jdn82WagP0Pw-xhcTK_' })
Vue.config.$recaptcha = recaptcha;

Vue.use(VueAxios, axios);
Vue.use(IsDemo);
Vue.use(VueMask, { masked: false });

Vue.config.productionTip = false

Vue.use(ArgonDashboard)
Vue.filter("date", function(value) {
    if (value) {
        return moment(String(value)).format("DD/MM/YYYY HH:mm");
    }
});
Vue.filter("date-only", function(value) {
    if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
    }
});
Vue.filter("dmy", function(value) {
    if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
    } else {
        return "não consta";
    }
});
Vue.filter("DDMMYYYY", function(value) {
    if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
    } else {
        return value;
    }
});
Vue.filter("MMMYY", function(value) {
    if (value) {
        return moment(String(value)).format("MMM/YY");
    } else {
        return value;
    }
});
Vue.filter("YEAR", function(value) {
    if (value) {
        return moment(String(value)).format("YYYY");
    } else {
        return value;
    }
});
Vue.filter("R$", function(value) {
    value = parseFloat(value);
    if (isNaN(value)) {
        return 'R$ 0,00';
    }
    return value.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        style: "currency",
        currency: "BRL"
    });
});
Vue.filter("qtd", function(value) {
    return value.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        style: "decimal",
    });
});
Vue.filter("number", function(x) {
    if (x)
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    else
        return 0
});

Vue.filter("number2", function(num) {
    if (num) {
        var digits = 1;
        var si = [
            { value: 1, symbol: "" },
            { value: 1E3, symbol: "" },
            { value: 1E6, symbol: "Mi" },
            { value: 1E9, symbol: "Bi" },
            { value: 1E12, symbol: "Tri" },
            { value: 1E15, symbol: "P" },
            { value: 1E18, symbol: "E" }
        ];
        var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var i;
        for (i = si.length - 1; i > 0; i--) {
            if (num >= si[i].value) {
                break;
            }
        }
        return 'R$' + ((num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol);
    } else
        return 'R$0'
});

Vue.filter("celular", function(x) {
    if (x){
        if(x.length == 11)
            return x.toString().replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
        if(x.length == 10)
            return x.toString().replace(/^(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    }
    else
        return null;
});

Vue.filter("cep", function(x) {
    if (x)
        return x.toString().replace(/^(\d{5})(\d{3})/, '$1-$2');
    else
        return null;
});

Vue.filter("cpf", function(x) {
    if (x)
        return x.toString().replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    else
        return null;
});

Vue.filter("rg", function(x) {
    if (x)
        return x.toString().replace(/^(\d{2})(\d{3})(\d{3})(\d{1})/, '$1.$2.$3-$4');
    else
        return null;
});

Vue.filter("money", function(dimdim) {
    if (dimdim) {
        dimdim += '';
        var v = dimdim.replace(/\D/g, '');

        v = (v / 100).toFixed(2) + '';
        v = v.replace(".", ",");
        v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
        v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
        dimdim = v;
        return 'R$ ' + dimdim;
    }
});

Vue.filter("porcent", function(value) {
    if (value) {
        value = parseFloat(value);
        return value.toFixed(2).replace(".", ",") + "%";
    }
    return "0%";
});

Vue.filter("hour", function(value) {
    if (value) {
        return moment.utc(value * 3600 * 1000).format("HH:mm");
    }
});

Vue.filter("nl2br", function(value) {
    if (value) {
        return value.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1<br />$2");
    }
});

const app = new Vue({
    router: router,
    store: store,
    el: "#app",
    data() {
        return {
            moneyConf: {
                masked: false,
                prefix: '',
                suffix: '',
                thousands: '.',
                decimal: ',',
                precision: 2,
                disableNegative: true,
                disabled: false,
                min: null,
                max: null,
                allowBlank: true,
                minimumNumberOfCharacters: 0,
            },
            modal: {
                excluir: {
                    isOpen: false,
                    model: {},
                    service: () => {},
                    mensagem: ''
                },
                form: {
                    isOpen: false,
                    model: {},
                }
            }
        }
    },
    created() {
        axios.interceptors.request.use(
            config => {
                this.loading = true;

                return config;
            },
            function(error) {
                app.loading = false;

                return Promise.reject(error);
            }
        );

        axios.interceptors.response.use(
            response => {


                return response;
            },
            function(error) {

                if (!error.response.data.errors &&
                    error.response.status != 403 &&
                    error.response.status != 422 &&
                    error.response.status != 401
                ) {
                    console.log(error.response)
                    app.$notify({
                        type: "danger",
                        message: "Ocorreu um erro no sistema, por favor recarregue a pagina",
                        horizontalAlign: 'center'
                    });
                }

                if (error.response.status === 403) {
                    app.$notify({
                        type: "danger",
                        message: "Você não possui permissão para esta ação",
                        horizontalAlign: 'center'
                    });

                }
                if (error.response.status === 401) {
                    app.$notify({
                        type: "danger",
                        message: "Você precisa estar logada para acessar esta pagina!",
                        horizontalAlign: 'center'
                    });
                    store.dispatch("logout");
                }

                return Promise.reject(error);
            }
        );
    },
    methods: {
        openExcluirModal(model, mensagem, service) {

            this.modal.excluir.mensagem = mensagem;
            this.modal.excluir.model = model;
            this.modal.excluir.service = service;
            this.modal.excluir.isOpen = true;

        },
    },
    render: h => h(App)
});

store.$app = app;