<template>
    <div class="">
        <div id="nav-trigger">
            <span></span>
        </div>

        <nav id="nav-mobile">
            <ul id="menu-navegacao-principal" class="menu">
                <li id="menu-item-7" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-7">
                    <a href="https://www.abflp.org.br/congresso/">Home</a>
                </li>
                <li id="menu-item-61" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-61">
                    <a>O Congresso</a>
                    <ul class="sub-menu">
                        <li id="menu-item-70" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-70"><a href="https://www.abflp.org.br/congresso/sobre-o-evento/">Sobre o Evento</a></li>
                        <li id="menu-item-69" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-69"><a href="https://www.abflp.org.br/congresso/mensagens-dos-presidentes/">Mensagens dos presidentes</a></li>
                        <li id="menu-item-68" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-68"><a href="https://www.abflp.org.br/congresso/comissoes/">Comissões</a></li>
                        <li id="menu-item-67" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-67"><a href="https://www.abflp.org.br/congresso/sobre-o-centro-e-associacoes/">Sobre o Centro e Associações</a></li>
                        <li id="menu-item-390" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-390"><a href="https://www.abflp.org.br/congresso/novidades/">Novidades</a></li>
                    </ul>
                </li>
                <li id="menu-item-332" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-332"><a href="https://www.abflp.org.br/congresso/endereco-do-evento/">Local</a></li>
                <li id="menu-item-190" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-190"><a href="https://www.abflp.org.br/congresso/hospedagem/">Hospedagem</a></li>
                <li id="menu-item-437" class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-418 current_page_item current-menu-ancestor current-menu-parent current_page_parent current_page_ancestor menu-item-has-children menu-item-437">
                    <a href="http://congressos.abflp.org.br/cadastro-congresso">Inscrições</a>
                    <ul class="sub-menu">
                        <li id="menu-item-80" class="menu-item menu-item-type-custom menu-item-object-custom current-menu-item menu-item-80"><a href="http://congressos.abflp.org.br/cadastro-congresso">Faça sua inscrição</a></li>
                        <li id="menu-item-81" class="menu-item menu-item-type-custom menu-item-object-custom current-menu-item menu-item-81"><a href="http://congressos.abflp.org.br/inscricao-trabalho">Inscrição de trabalhos</a></li>
                        <li id="menu-item-1084" class="menu-item menu-item-type-custom menu-item-object-custom current-menu-item menu-item-1084"><a href="http://congressos.abflp.org.br/certificado">Receber Certificado</a></li>
                    </ul>
                </li>
                <li id="menu-item-64" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-64">
                    <a>Programação Científica</a>
                    <ul class="sub-menu">
                        <!-- <li id="menu-item-74" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-74"><a
                href="https://www.abflp.org.br/congresso/convidados-palestrantes/">Convidados Palestrantes</a></li> -->
                        <li id="menu-item-71" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-71"><a href="https://www.abflp.org.br/congresso/programa-oficial/">Programa Oficial</a></li>
                        <li id="menu-item-425" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-425"><a href="https://www.abflp.org.br/congresso/palestrantes/">Palestrantes</a></li>
                        <li id="menu-item-882" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-882"><a href="https://www.abflp.org.br/congresso/pre-congresso/">Pré-Congresso</a></li>
                    </ul>
                </li>
                <li id="menu-item-65" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-65">
                    <a>Programação Social</a>
                    <ul class="sub-menu">
                        <li id="menu-item-76" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-76"><a href="https://www.abflp.org.br/congresso/recepcao-e-coquetel-de-abertura/">Recepção e Coquetel de Abertura</a></li>
                        <li id="menu-item-75" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-75"><a href="https://www.abflp.org.br/congresso/brinde-ao-o-xiv-congresso-brasileiro-de-fissuras-labio-palatinas-e-anomalias-craniofaciais/">Brinde ao XIV Congresso Brasileiro de Fissuras Lábio Palatinas e Anomalias Craniofaciais</a></li>
                        <li id="menu-item-893" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-893"><a href="https://www.abflp.org.br/congresso/apresentacoes-artisticas-e-culturais/">Apresentações artísticas e culturais</a></li>
                    </ul>
                </li>
                <li id="menu-item-984" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-984"><a href="https://www.abflp.org.br/congresso/patrocinadores/">Patrocínio</a></li>
                <li id="menu-item-940" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-940">
                    <a href="https://www.facebook.com/profile.php?id=100090478887756&#038;mibextid=LQQJ4d"><i class="fa fa-facebook-official" aria-hidden="true" style="font-size: 20px"></i></a>
                </li>
                <li id="menu-item-941" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-941">
                    <a href="https://instagram.com/congressofissuras2023?igshid=YmMyMTA2M2Y="><i class="fa fa-instagram" aria-hidden="true" style="font-size: 20px"></i></a>
                </li>
            </ul>
        </nav>

        <header>
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 col-md-8 col-sm-12">
                        <nav id="menu">
                            <ul id="menu-navegacao-principal-1" class="menu">
                                <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-7"><a href="https://www.abflp.org.br/congresso/">Home</a></li>
                                <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-61">
                                    <a>O Congresso</a>
                                    <ul class="sub-menu">
                                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-70"><a href="https://www.abflp.org.br/congresso/sobre-o-evento/">Sobre o Evento</a></li>
                                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-69"><a href="https://www.abflp.org.br/congresso/mensagens-dos-presidentes/">Mensagens dos presidentes</a></li>
                                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-68"><a href="https://www.abflp.org.br/congresso/comissoes/">Comissões</a></li>
                                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-67"><a href="https://www.abflp.org.br/congresso/sobre-o-centro-e-associacoes/">Sobre o Centro e Associações</a></li>
                                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-390"><a href="https://www.abflp.org.br/congresso/novidades/">Novidades</a></li>
                                    </ul>
                                </li>
                                <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-332"><a href="https://www.abflp.org.br/congresso/endereco-do-evento/">Local</a></li>
                                <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-190"><a href="https://www.abflp.org.br/congresso/hospedagem/">Hospedagem</a></li>
                                <li class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-418 current_page_item current-menu-ancestor current-menu-parent current_page_parent current_page_ancestor menu-item-has-children menu-item-437">
                                    <a href="https://www.abflp.org.br/congresso/inscricoes/">Inscrições</a>
                                    <ul class="sub-menu">
                                        <li class="menu-item menu-item-type-custom menu-item-object-custom current-menu-item menu-item-80"><a href="http://congressos.abflp.org.br/cadastro-congresso">Faça sua inscrição</a></li>
                                        <li class="menu-item menu-item-type-custom menu-item-object-custom current-menu-item menu-item-81"><a href="http://congressos.abflp.org.br/inscricao-trabalho">Inscrição de trabalhos</a></li>
                                        <li class="menu-item menu-item-type-custom menu-item-object-custom current-menu-item menu-item-1084"><a href="http://congressos.abflp.org.br/certificado">Receber Certificado</a></li>
                                    </ul>
                                </li>
                                <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-64">
                                    <a>Programação Científica</a>
                                    <ul class="sub-menu">
                                        <!-- <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-74"><a
                        href="https://www.abflp.org.br/congresso/convidados-palestrantes/">Convidados Palestrantes</a>
                    </li> -->
                                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-71"><a href="https://www.abflp.org.br/congresso/programa-oficial/">Programa Oficial</a></li>
                                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-425"><a href="https://www.abflp.org.br/congresso/palestrantes/">Palestrantes</a></li>
                                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-882"><a href="https://www.abflp.org.br/congresso/pre-congresso/">Pré-Congresso</a></li>
                                    </ul>
                                </li>
                                <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-65">
                                    <a>Programação Social</a>
                                    <ul class="sub-menu">
                                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-76"><a href="https://www.abflp.org.br/congresso/recepcao-e-coquetel-de-abertura/">Recepção e Coquetel de Abertura</a></li>
                                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-75"><a href="https://www.abflp.org.br/congresso/brinde-ao-o-xiv-congresso-brasileiro-de-fissuras-labio-palatinas-e-anomalias-craniofaciais/">Brinde ao XIV Congresso Brasileiro de Fissuras Lábio Palatinas e Anomalias Craniofaciais</a></li>
                                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-893"><a href="https://www.abflp.org.br/congresso/apresentacoes-artisticas-e-culturais/">Apresentações artísticas e culturais</a></li>
                                    </ul>
                                </li>
                                <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-984"><a href="https://www.abflp.org.br/congresso/patrocinadores/">Patrocínio</a></li>
                                <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-940">
                                    <a href="https://www.facebook.com/profile.php?id=100090478887756&#038;mibextid=LQQJ4d"><i class="fa fa-facebook-official" aria-hidden="true" style="font-size: 20px"></i></a>
                                </li>
                                <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-941">
                                    <a href="https://instagram.com/congressofissuras2023?igshid=YmMyMTA2M2Y="><i class="fa fa-instagram" aria-hidden="true" style="font-size: 20px"></i></a>
                                </li>
                            </ul>
                        </nav>
                    </div>

                    <div class="col-lg-2 col-md-4 col-sm-10">
                        <a href="http://congressos.abflp.org.br/cadastro-congresso" class="inscricao" style="margin: 0 !important"> inscreva-se aqui </a>
                    </div>
                </div>
            </div>
        </header>
        <div id="title">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12" v-if="isLogin">
                        <h1>Acesso ao sistema</h1>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12" v-if="isProjeto">
                        <h1>Inscrição de Trabalhos</h1>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12" v-if="isInscricao">
                        <h1>Inscreva-se em Congresso e Curso</h1>
                    </div>
                </div>
            </div>
        </div>

        <!-- Navbar -->

        <!-- Header -->
        <div class="header py-7 py-lg-8 pt-lg-9">
            <div class="container">
                <div class="header-body text-center mb-4">
                    <div class="text-center" style="margin-bottom: 5px">
                        <!-- <div v-if="isRegister">
              <div class="row justify-content-center">
                <div class="col-xl-5 col-lg-6 col-md-8 px-5">
                  <h1 class="text-white">Create an account</h1>
                  <p class="text-lead text-white">
                    Use these awesome forms to login or create new account in
                    your project for free.
                  </p>
                </div>
              </div>
            </div> -->
                        <div v-if="isPassword">
                            <div class="row justify-content-center">
                                <div class="col-xl-5 col-lg-6 col-md-8 px-5">
                                    <p class="pb-0"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div> -->
        </div>
        <!-- Page content -->
        <div class="container mt--8 pb-5">
            <slide-y-up-transition mode="out-in" origin="center top">
                <router-view></router-view>
            </slide-y-up-transition>
        </div>
        <!-- <footer class="py-5" id="footer-main">
      <div class="container">
        <div class="row align-items-center justify-content-xl-between">
          <div class="col-xl-6">
            <!-- <ul class="nav nav-footer">
              <li class="nav-item">
                <a
                  href="https://www.creative-tim.com"
                  class="nav-link"
                  target="_blank"
                  rel="noopener"
                  >CREATIVE TIM</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="https://www.updivision.com"
                  class="nav-link"
                  target="_blank"
                  rel="noopener"
                  >UPDIVISION</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="https://www.creative-tim.com/presentation"
                  class="nav-link"
                  target="_blank"
                  rel="noopener"
                  >ABOUT US</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="https://blog.creative-tim.com"
                  class="nav-link"
                  target="_blank"
                  rel="noopener"
                  >BLOG</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="https://www.creative-tim.com/license"
                  class="nav-link"
                  target="_blank"
                  rel="noopener"
                  >LICENSES</a
                >
              </li>
            </ul> 
          </div>
          <div class="col-xl-6 pr-1">
            <div class="copyright text-center text-lg-right text-muted">
              © {{ year }}, feito por<a href="https://www.marcasite.com.br" class="font-weight-bold ml-1" target="_blank" rel="noopener" >Marcasite</a>.
            </div>
          </div>
        </div>
      </div>
    </footer> -->

        <div id="patrocinio">
            <div class="container">
                <div class="row justify-content-md-center">
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <h1>Realização</h1>
                        <div class="logo">
                            <img src="http://www.abflp.org.br/congresso/wp-content/uploads/2017/10/logo-abflp-footer.png" alt="" style="height: 205px" />
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <h1>Realização</h1>
                        <div class="logo">
                            <img src="http://www.abflp.org.br/congresso/wp-content/uploads/2023/04/image002.png" alt="" style="height: 205px" />
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <h1>Patrocinador Master</h1>
                        <div class="logo">
                            <img src="http://www.abflp.org.br/congresso/wp-content/themes/congressoabccmf/images/smiletrain.jpg" alt="" style="padding: 0px; height: 205px" />
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <h1>Agência Oficial</h1>
                        <div class="logo">
                            <img src="http://www.abflp.org.br/congresso/wp-content/uploads/2023/03/luck__alta-removebg-preview.png" alt="" style="padding: 0px; height: 205px" />
                        </div>
                    </div>
                </div>

                <div class="row justify-content-md-center" style="margin-top: 25px">
                    <div class="col-lg-2 col-md-3 col-sm-12">
                        <h1>Apoio</h1>

                        <div class="logo">
                            <img src="http://www.abflp.org.br/congresso/wp-content/themes/congressoabccmf/images/CasaSorrir.png" alt="" style="padding: 0px" />
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-3 col-sm-12">
                        <h1><br /></h1>

                        <div class="logo">
                            <img src="http://www.abflp.org.br/congresso/wp-content/themes/congressoabccmf/images/Recife_Convention.PNG" alt="" style="padding: 7px" />
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-3 col-sm-12">
                        <h1><br /></h1>

                        <div class="logo">
                            <img src="http://www.abflp.org.br/congresso/wp-content/themes/congressoabccmf/images/ABEOC.jpg" alt="" style="padding: 0px" />
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-3 col-sm-12">
                        <h1><br /></h1>

                        <div class="logo">
                            <img src="http://www.abflp.org.br/congresso/wp-content/themes/congressoabccmf/images/FAF.jpg" alt="" style="padding: 15px 10px" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <footer>
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-12 col-sm-12">
                        <img src="http://www.abflp.org.br/congresso/wp-content/uploads/2023/03/marca_vertical.png" alt="Logo" style="width: 100%; max-width: 256px; background: #fff; padding: 30px; margin-top: 45px" />
                    </div>

                    <div class="col-lg-2 col-md-12 col-sm-12">
                        <h2>O Congresso</h2>
                        <ul id="menu-navegacao-congresso" class="menu">
                            <li id="menu-item-24" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-24"><a href="https://www.abflp.org.br/congresso/sobre-o-evento/">Sobre o Evento</a></li>
                            <li id="menu-item-31" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-31"><a href="https://www.abflp.org.br/congresso/mensagens-dos-presidentes/">Mensagens dos presidentes</a></li>
                            <li id="menu-item-32" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-32"><a href="https://www.abflp.org.br/congresso/comissoes/">Comissões</a></li>
                            <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-33"><a href="https://www.abflp.org.br/congresso/sobre-o-centro-e-associacoes/">Sobre o Centro e Associações</a></li>
                            <li id="menu-item-907" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-907"><a href="https://www.abflp.org.br/congresso/novidades/">Novidades</a></li>
                        </ul>
                    </div>

                    <div class="col-lg-2 col-md-12 col-sm-12">
                        <h2>Inscrição</h2>
                        <ul id="menu-navegacao-inscricao" class="menu">
                            <li id="menu-item-58" class="menu-item menu-item-type-custom menu-item-object-custom current-menu-item menu-item-58"><a href="http://congressos.abflp.org.br/cadastro-congresso">Faça sua inscrição</a></li>
                            <li id="menu-item-59" class="menu-item menu-item-type-custom menu-item-object-custom current-menu-item menu-item-59"><a href="http://congressos.abflp.org.br/inscricao-trabalho">Inscrição de trabalhos</a></li>
                        </ul>
                    </div>

                    <div class="col-lg-2 col-md-12 col-sm-12">
                        <h2>Programação</h2>
                        <ul id="menu-navegacao-programacao" class="menu">
                            <!-- <li id="menu-item-47" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-47"><a
                href="https://www.abflp.org.br/congresso/convidados-palestrantes/">Convidados Palestrantes</a></li> -->
                            <li id="menu-item-44" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-44"><a href="https://www.abflp.org.br/congresso/programa-oficial/">Programa Oficial</a></li>
                            <li id="menu-item-424" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-424"><a href="https://www.abflp.org.br/congresso/palestrantes/">Palestrantes</a></li>
                            <li id="menu-item-915" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-915"><a href="https://www.abflp.org.br/congresso/pre-congresso/">Pré-Congresso</a></li>
                        </ul>
                    </div>

                    <div class="col-lg-2 col-md-12 col-sm-12">
                        <h2>Social</h2>
                        <ul id="menu-navegacao-social" class="menu">
                            <li id="menu-item-55" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-55"><a href="https://www.abflp.org.br/congresso/recepcao-e-coquetel-de-abertura/">Recepção e Coquetel de Abertura</a></li>
                            <li id="menu-item-54" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-54"><a href="https://www.abflp.org.br/congresso/brinde-ao-o-xiv-congresso-brasileiro-de-fissuras-labio-palatinas-e-anomalias-craniofaciais/">Brinde ao XIV Congresso Brasileiro de Fissuras Lábio Palatinas e Anomalias Craniofaciais</a></li>
                            <li id="menu-item-920" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-920"><a href="https://www.abflp.org.br/congresso/apresentacoes-artisticas-e-culturais/">Apresentações artísticas e culturais</a></li>
                        </ul>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <hr />
                    </div>

                    <div class="col-lg-7 col-md-12 col-sm-12">
                        <a class="facebook" style="color: #5d9ddc; float: left">Organização: TM Tatiana Marques. WhatsApp (81) 99971-7529<br />(enviar a sua mensagem, por gentileza, das 9h às 19h e de segunda a sexta)</a>
                    </div>

                    <div class="col-lg-2 col-md-12 col-sm-12">
                        <a href="https://www.facebook.com/profile.php?id=100090478887756&mibextid=LQQJ4d" class="facebook" target="_blank">curta no facebook <i class="fa fa-facebook-official" aria-hidden="true"></i></a>
                    </div>
                    <div class="col-lg-3 col-md-12 col-sm-12">
                        <a href="https://instagram.com/congressofissuras2023?igshid=YmMyMTA2M2Y=" class="facebook" target="_blank">siga nosso instagram <i class="fa fa-instagram" aria-hidden="true"></i></a>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>
<script>
import { SlideYUpTransition } from "vue2-transitions";

export default {
    name: "auth-layout",
    components: {
        SlideYUpTransition,
    },
    data() {
        return {
            year: new Date().getFullYear(),
            showMenu: false,
        };
    },
    mounted() {
        $(document).ready(function () {
            $("#nav-trigger").click(function () {
                if ($("nav#nav-mobile ul").hasClass("expanded")) {
                    $("nav#nav-mobile ul.expanded").removeClass("expanded").slideUp(250);
                    $("#nav-trigger span").removeClass("open");
                    $("#nav-trigger").removeClass("close");
                } else {
                    $("nav#nav-mobile ul").addClass("expanded").slideDown(250);
                    $("#nav-trigger span").addClass("open");
                    $("#nav-trigger").addClass("close");
                }
            });
        });
    },
    computed: {
        isLogin() {
            return this.$route.name === "login";
        },
        isProjeto() {
            return this.$route.name === "inscricao-trabalho";
        },
        isInscricao() {
            return this.$route.name === "cadastro-congresso";
        },
        isRegister() {
            return this.$route.name === "register";
        },
        isPassword() {
            return this.$route.name === "PasswordReset";
        },
    },
};
</script>
<style>
/* @import 'https://www.abflp.org.br/congresso/wp-content/plugins/captcha/css/front_end_style.css?ver=4.3.6'; */
/* @import 'https://www.abflp.org.br/congresso/wp-includes/css/dashicons.min.css?ver=4.8.2'; */
/* @import 'https://www.abflp.org.br/congresso/wp-content/plugins/captcha/css/desktop_style.css?ver=4.3.6'; */
/* @import 'https://www.abflp.org.br/congresso/wp-content/plugins/contact-form-7/includes/css/styles.css?ver=4.9'; */
/* @import 'https://www.abflp.org.br/congresso/wp-content/plugins/wp-pagenavi/pagenavi-css.css?ver=2.70'; */
/* @import 'https://www.abflp.org.br/congresso/wp-content/plugins/simple-lightbox/client/css/app.css?ver=2.6.0'; */
@import "https://www.abflp.org.br/congresso/wp-content/themes/congressoabccmf/style.css";
@import "https://www.abflp.org.br/congresso/wp-content/themes/congressoabccmf/responsive.css";
/* @import 'https://www.abflp.org.br/congresso/wp-content/themes/congressoabccmf/css/bootstrap.css'; */
@import "//maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css?ver=4.8.5";

#patrocinio .logo img {
    width: 100%;
    height: 140px;
    padding: 20px;
    object-fit: contain;
    background-color: white;
}

#patrocinio {
    z-index: unset;
}

footer {
    z-index: unset;
}
</style>
