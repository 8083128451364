<template>
  <div class="form-group" :class="[
    { 'input-group': hasIcon },
    // {'has-danger': errors.length},
    { 'focused': focused },
    { 'has-label': label || $slots.label },

  ]">
    <slot name="label">
      <label v-if="label" class="form-control-label" :class="labelClasses">
        {{ label }}
        <span v-if="required">*</span>
      </label>
    </slot>


    <div v-if="addonLeftIcon || $slots.addonLeft" class="input-group-prepend">
      <span class="input-group-text">
        <slot name="addonLeft">
          <i :class="addonLeftIcon"></i>
        </slot>
      </span>
    </div>
    <slot v-bind="slotData">
      <div>
        <vue-multi-select 
          :value="value"
          v-model="values" 
          :options="options" 
          :btnLabel="btnLabel" 
          :searchPlaceholder="search"
          :selectOptions="data" 
          search 
          
          v-on="listeners"
          v-bind="$attrs" 
          class="form-control" 
          :class="[
            { 'is-valid': valid === true },
            { 'is-invalid': valid === false }, inputClasses]" a
            ria-describedby="addon-right addon-left" />
      </div>
    </slot>
    <div v-if="addonRightIcon || $slots.addonRight" class="input-group-append">
      <span class="input-group-text">
        <slot name="addonRight">
          <i :class="addonRightIcon"></i>
        </slot>
      </span>
    </div>
    <slot name="infoBlock"></slot>
    <slot name="helpBlock">
      <!--   <div class="text-danger invalid-feedback" style="display: block;" :class="{'mt-2': hasIcon}" v-if="error">
      {{ error }}
    </div> -->
      <div class="text-danger invalid-feedback" style="display: block;" :class="{ 'mt-2': hasIcon }"
        v-for="erro in errors" v-if="errors">
        {{ erro }}
      </div>
    </slot>
  </div>
</template>

<script>
import vueMultiSelect from 'vue-multi-select';
import 'vue-multi-select/dist/lib/vue-multi-select.css';



export default {
  name: 'base-multiselect',
  components: {
    vueMultiSelect,
  },
  props: {
    data: {
      type: Array,
    },
    required: {
      type: Boolean,
      description: "Whether input is required (adds an asterix *)"
    },
    // valid: {
    //   type: Boolean,
    //   description: "Whether is valid",
    //   default: undefined
    // },
    label: {
      type: String,
      description: "Input label (text before input)"
    },
    // error: {
    //   type: String,
    //   description: "Input error (below input)"
    // },
    errors: {
      type: Array,
      default: () => [],
      description: "Input error (below input)"
    },
    labelClasses: {
      type: String,
      description: "Input label css classes"
    },
    inputClasses: {
      type: String,
      description: "Input css classes"
    },
    value: {
      type: [Array, Object],
      description: "Input value"
    },
    selecteds: {
      type: [Array, Object],
      default: () => [],
      description: "Input value"
    },
    addonRightIcon: {
      type: String,
      description: "Addon right icon"
    },
    addonLeftIcon: {
      type: String,
      description: "Addont left icon"
    }
  },
  data() {
    return {
      focused: false,
      search: 'Procurar...',
      btnLabel: function (values) { if (values.length == 0) { return `Selecione` } else { let text = values.map(u => u.name).join(', '); return text.substring(0, 18) + (text.length > 18 ? "..." : ""); } },
      values: [],
      options: {
        multi: true,
        groups: false,
      },
    };
  },

  computed: {
    valid() {
      return this.errors.length
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur
      };
    },
    slotData() {
      return {
        focused: this.focused,
        ...this.listeners
      };
    },
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots;
      return (
        addonRight !== undefined ||
        addonLeft !== undefined ||
        this.addonRightIcon !== undefined ||
        this.addonLeftIcon !== undefined
      );
    }
  },
  methods: {
    updateValue() {
      this.$emit('input',
        // this.values.map(obj => obj.id)
        this.values
      );
    },
    onFocus() {
      this.focused = true;
      this.$emit("focus", this.values);
    },
    onBlur() {
      this.focused = false;
      this.$emit("blur", this.values);
    }
  },
  async mounted(){
    let value = this.value;
    await new Promise(resolve => setTimeout(resolve, 1000));
    this.values = value;
  }
};
</script>
<style>
.btn-select {
  width: 100% !important;
  border: 0px !important;
  margin-top: -12px !important;
}

.btn-select:focus {
  border: 0px !important;
}
</style>
