<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-6 pt-5 pt-md-5">
    </base-header>
    <div class="container-fluid mt-5">

      <card body-classes="px-0 pb-1" footer-classes="pb-2">
        <div class="card-header border-0">
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0">Lista <i class="fa fa-spinner fa-spin" v-if="loading"></i></h3>
            </div>
            <div class="col text-right">
              <base-button type="primary" icon size="sm" @click="exportInscritos()">
                <span class="btn-inner--text">Exportar</span>
              </base-button>
              <base-button type="primary" icon size="sm" @click="toogleStatus()">
                <span class="btn-inner--text">{{ textToggle }}</span>
              </base-button>
            </div>
          </div>
        </div>

        <div class="table-responsive">
          <table class="table align-items-center table-flush tablesorter"
                  tbody-classes=" list" :data="list">
            <thead class="thead-light">
              <tr>
                <th>Nome</th>
                <th>Email</th>
                <th>Celular / Telefone</th>
                <th>Detalhes</th>
                <th>Status</th>
                <th>Inscrição</th>

              </tr>
            </thead>

            <tbody class="list">
              <tr v-for="row in list" :key="row.id">
                <td>
                  {{ row.name }}
                </td>
                <td>
                  {{ row.email }}
                </td>
                <td>
                  {{ row.celular | celular }} {{ row.telefone ? '/' : '' }} {{ row.telefone | celular }}
                </td>
                <td> <a href="#" @click="showDetalhes(row)">Detalhes</a> </td>
                <td>
                  {{ row.status }}
                </td>
                <td>
                  {{ row.created_at | date }}
                </td>


              </tr>
              <tr v-if="!list.length && !loading">
                <td colspan="100" style="text-align: center;">
                  Nenhum inscrito encontrado
                </td>
              </tr>
              <tr v-if="loading">
                <td colspan="100" style="text-align: center;">
                  <i class="fa fa-spinner fa-spin" v-if="loading" style="font-size: 20px"></i>
                </td>
              </tr>
            </tbody>

          </table>
        </div>

        <div class="card-footer d-flex justify-content-end">
          <base-pagination :pagination="pagination" @change="listar()" align="center"></base-pagination>
        </div>
      </card>
    </div>

    <modal :show='show' @close='close()' modal-classes="modal-lg">
      <template v-slot:header>
        Detalhes
      </template>
      <template>
        Nome: {{ currentRow.name }} <br />
        Nome no Crachá: {{ currentRow.name_cracha }} <br />
        Sexo: {{ currentRow.sexo }} <br />
        CPF: {{ currentRow.cpf | cpf }} <br />
        Nascimento: {{ currentRow.nascimento | DDMMYYYY }} <br />
        RG: {{ currentRow.rg | rg }} <br />
        Celular/Telefone: {{ currentRow.celular | celular }} {{ currentRow.telefone ? '/' : '' }} {{ currentRow.telefone |
          celular }} <br />
        Email: {{ currentRow.email }} <br />
        Cep: {{ currentRow.cep | cep }} <br />
        Endereco: {{ currentRow.endereco }}, {{ currentRow.numero }} {{ currentRow.complemento ? ', '
          + currentRow.complemento : '' }} - {{ currentRow.cidade }} - {{ currentRow.uf }}<br />
        Especialidade: {{ currentRow.especialidade }} <br />
        Instituição: {{ currentRow.instituicao }} <br />
        Categoria: {{ currentRow.categoria ? currentRow.categoria.name : '' }} <br />
        Associado: {{ currentRow.associacao ? currentRow.associacao.name : '' }} <br />
        Seguro Saúde: {{ currentRow.seguro_saude ? 'Sim' : 'Não' }} <br />
        <br />
        <a v-if="currentRow.arquivo" href="#" @click="downloadFile(currentRow.id)">Baixar Arquivo(Comprovante associação)</a>
        <span v-else>Não há arquivo(Comprovante associação).</span>  
      </template>
      <template v-slot:footer>

        <button type="button" class="btn base-button ml-auto btn-link" @click="close()">Fechar</button>
      </template>
    </modal>

  </div>
</template>
<script>
import Service from "@/services/Cursos";
import FileSaver from 'file-saver';

export default {
  data() {
    return {
      show: false,
      currentRow: {},
      textToggle: 'Mostrar Todos',
      status: 'pagos',
      curso_id: null,
      Service,
      loading: false,
      pagination: {
        page: 1,
        last_page: 1,
        per_page: 20,
        total: 1
      },
      // orderBy: {
      //   campo: "FORNECEDOR",
      //   ordem: "asc",
      // },
      list: [
        // {
        //   name: "Admin",
        //   email: "admin@jsonapi.com",
        //   created_at: "2020-01-01",
        // },
      ],
    };
  },
  created() {
    this.curso_id = this.$route.params.id;
    this.listar();
  },
  methods: {
    showDetalhes(row) {
      this.currentRow = row;
      this.show = true;
    },
    close(update) {
      this.show = false;
    },
    exportInscritos() {
      Service.exportar(this.curso_id).then((response) => {
        FileSaver.saveAs(response.data, "inscritos");
      }).catch(errors => {
        console.log(errors);
      });
    },
    downloadFile(id) {
      Service.download(id).then((response) => {
        FileSaver.saveAs(response.data, "arquivo");
      }).catch(errors => {
        console.log(errors);
      });
    },
    toogleStatus() {
      if (this.status == 'pagos') {
        this.status = 'todos';
        this.textToggle = "Mostrar Pagos";
      }
      else {
        this.status = 'pagos';
        this.textToggle = "Mostrar Todos";
      }
      this.listar();
    },
    handleExcluir(updated) {
      this.$root.modal.excluir.isOpen = false
      if (updated)
        this.listar();
    },
    listar() {
      this.loading = true;
      this.list = [];
      Service.getInscritos(this.curso_id, { ...this.pagination, search: this.search, status: this.status }).then(response => {
        this.list = response.data.data;
        this.pagination.last_page = response.data.last_page;
        this.pagination.total = response.data.total;
        this.loading = false;
      });
    },
  },
};
</script>
<style></style>
  