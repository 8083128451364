var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Usuários',
          icon: 'fa fa-users text-yellow',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Adicionar', path: '/usuarios/adicionar' }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Listar',
            path: '/usuarios/listar',
             icon: 'fa fa-list',
          }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Cursos/Congressos',
          icon: 'ni ni-hat-3 text-orange',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Adicionar', path: '/cursos/adicionar' }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Listar',
            path: '/cursos/listar',
             icon: 'fa fa-list',
          }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Categorias',
          icon: 'ni ni-tag text-blue',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Adicionar', path: '/categorias/adicionar' }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Listar',
            path: '/categorias/listar',
             icon: 'fa fa-list',
          }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Associações',
          icon: 'ni ni-badge text-green',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Adicionar', path: '/associacoes/adicionar' }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Listar',
            path: '/associacoes/listar',
             icon: 'fa fa-list',
          }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Pedidos',
          icon: 'ni ni-basket text-primary',
          path: '/pedidos/listar',
        }}})],1)],2),_c('div',{staticClass:"main-content",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('div',{on:{"click":_vm.toggleSidebar}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }