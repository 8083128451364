<template>
  <div>
   <base-header class="pb-6 pb-6 pt-5 pt-md-5">
 </base-header>

 <div class="container-fluid mt-5">
  <div class="row">
    <div class="col-xl-12 order-xl-1">
      <card shadow type="secondary">
        <div slot="header" class="bg-white border-0">
          <div class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">Categoria</h3>
            </div>
            <div class="col-4 text-right">
              <!-- <a href="#!" class="btn btn-sm btn-primary">Settings</a> -->
            </div>
          </div>
        </div>
        <template>
          <form @submit.prevent>
            <h6 class="heading-small text-muted mb-4">Informações da categoria</h6>
            
            <div class="row">
              <div class="col-12">
                <base-input
                alternative=""
                required
                label="Nome"
                placeholder="nome do categoria"
                input-classes="form-control-alternative"
                :errors="errors.name"
                v-model="model.name"

                />
              </div>
            </div>
          </form>
        </template>


        <button type="submit" class="btn base-button btn-dark float-left" @click="$router.push('/categorias/listar')">Ver listagem</button>
        <button type="submit" class="btn base-button btn-primary float-right" @click="submit()" :disabled="loading">Salvar categoria <i class="fa fa-spinner fa-spin" v-if="loading"></i></button>



      </card>
    </div>
  </div>
</div>
</div>
</template>
<script>
  import Service from "@/services/Categorias";
  export default {
    name: "categoria_form",
    created(){
      if(this.$route.params.id){
        this.model.id =this.$route.params.id;
        this.find();
      }
    }, 
    data() {
      return {
        errors:{},
        loading:false,
        Service,
        model: {
          nome: "",
        },
      };
    },
    methods: {
     find(){
      this.loading = true;
      this.errors = {};
      Service.find(this.model.id).then(response => {
        this.model = response.data;
        this.loading = false;        
      }).catch((err) => {
        this.loading = false;
      });
    },
    submit(){
      this.loading = true;
      this.errors = {};
      Service.submit(this.model).then(response => {
        this.model = response.data;
        this.loading = false;
        this.$notify({
          type: "success",
          message: "Categoria salva com sucesso!",
          horizontalAlign:'center'
        });
        this.model= {
          nome: "",
        };
      }).catch((err) => {
        this.loading = false;
        if(err.response && err.response.data && err.response.data.errors){            
          let errors = err.response.data.errors;                      
          this.errors = errors;
          this.$notify({
            type: "danger",
            message: "Por favor verifique o formulário",
            horizontalAlign:'center'
          });
        }
      });;
    },
  }
};
</script>
<style></style>
