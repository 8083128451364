import axios from "axios";
import { helper } from "../helper.js";
import Abstract from "./Abstract";
export const base_url = "/admin/pedidos";
export const url = process.env.VUE_APP_API_BASE_URL + base_url;
export const abstract = new Abstract(url);
export default class Categorias {
  static get(params) {
    return abstract.get({ ...params });
  }

  static find(id, params) {
    return abstract.find(id, params);
  }

  static update(model) {
    return axios.post(
      url + "/" + model.id,
      helper.getFormData({ ...model, _method: "put" })
    );
  }

  static create(model) {
    return axios.post(url, helper.getFormData(model));
  }

  static submit(model) {
    return abstract.submit(model, false); // qnd true é passado é para ser convertido em formData
  }

  static delete(id) {
    return abstract.delete(id);
  }

  static setPaid(id) {
    return axios.post(url + "/" + id + "/status/3");
  }

  static setNoPaid(id) {
    return axios.post(url + "/" + id + "/status/1");
  }

  static getStatus() {
    return [
      { id: null, name: "Todos" },
      { id: [1, "WAITING"], name: "Aguardando Pagamento" },
      //   { id: [2], name: "Em análise" },
      { id: [3, 4, "PAID"], name: "Pago" },
      //   { id: [5], name: "Em disputa" },
      //   { id: [6, "CANCELED"], name: "Devolvida" },
      { id: [7, "DECLINED"], name: "Cancelada" },
      //   { id: 9, name: "Em contestação" },
      //   { id: 10, name: "Em devolução" },
    ];
  }

  static exportar(params) {
    return axios.post(url + "/exportar", params, {
      responseType: "blob",
    });
  }
}
